import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useData } from './dataContext';
import { baseURL } from 'constants/constant';

const PaymentContext = createContext();

// You can configure this based on your environment
const ADMIN_ID = '67250ded0f689ab7f255f5f8';
const BASE_URL = `${baseURL}`;

export function PaymentProvider({ children }) {
  const [dashboardStats, setDashboardStats] = useState(null);
  const [paymentStats, setPaymentStats] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchDashboardStats = async () => {
    try {
      const response = await axios.post(`${BASE_URL}getDashboardStats`, {
        id: ADMIN_ID
      });
      setDashboardStats(response.data.stats);
    } catch (error) {
      console.error('Error fetching dashboard stats:', error);
    }
  };

  const fetchPaymentStats = async () => {
    try {
      const response = await axios.post(`${BASE_URL}getPaymentStatistics`, {
        id: ADMIN_ID
      });
      setPaymentStats(response.data);
    } catch (error) {
      console.error('Error fetching payment stats:', error);
    }
  };

  const fetchPaymentsByType = async (type = 'all') => {
    try {
      const response = await axios.post(`${BASE_URL}getPaymentsByType`, {
        id: ADMIN_ID,
        type
      });
      return response.data.payments;
    } catch (error) {
      console.error('Error fetching payments by type:', error);
      return [];
    }
  };

  const fetchPaymentsByDateRange = async (startDate, endDate) => {
    try {
      const response = await axios.post(`${BASE_URL}getPaymentsByDateRange`, {
        id: ADMIN_ID,
        startDate,
        endDate
      });
      return response.data.payments;
    } catch (error) {
      console.error('Error fetching payments by date range:', error);
      return [];
    }
  };

  useEffect(() => {
    Promise.all([
      fetchDashboardStats(),
      fetchPaymentStats()
    ]).finally(() => setLoading(false));
  }, []);

  const value = {
    dashboardStats,
    paymentStats,
    loading,
    fetchDashboardStats,
    fetchPaymentStats,
    fetchPaymentsByType,
    fetchPaymentsByDateRange
  };

  return (
    <PaymentContext.Provider value={value}>
      {children}
    </PaymentContext.Provider>
  );
}

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};
