import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function TopUserTable() {
  const [translations, setTranslations] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  // Update to include translationType
  const [newTranslation, setNewTranslation] = React.useState({
    translationType: '',
    translationEn: '',
    translationEs: '',
    translationFr: '',
    translationIt: '',
    translationPg: '',
    translationCh: '',
    translationRu: '',
    id: '67250ded0f689ab7f255f5f8',
  });

  const [editTranslation, setEditTranslation] = React.useState({
    translationType: '',
    translationEn: '',
    translationEs: '',
    translationFr: '',
    translationIt: '',
    translationPg: '',
    translationCh: '',
    translationRu: '',
    id: '67250ded0f689ab7f255f5f8',
    translationId: '',
  });

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  // Fetch Translations
  React.useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await axios.post(`${baseURL}getTranslation`, {
          id: '67250ded0f689ab7f255f5f8',
        });
        setTranslations(response.data.translations);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    };
    fetchTranslations();
  }, []);

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Check if we are editing or adding a new translation
    if (editTranslation.translationId) {
      setEditTranslation((prev) => ({ ...prev, [name]: value }));
    } else {
      setNewTranslation((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Add Translation
  const handleAddTranslation = async () => {
    try {
      await axios.post(`${baseURL}addTranslation`, newTranslation);
      const response = await axios.post(`${baseURL}getTranslation`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setTranslations(response.data.translations);
      onAddClose();
      // Reset new translation fields
      setNewTranslation({
        translationType: '',
        translationEn: '',
        translationEs: '',
        translationFr: '',
        translationIt: '',
        translationPg: '',
        translationCh: '',
        translationRu: '',
        id: '67250ded0f689ab7f255f5f8',
      });
    } catch (error) {
      console.error('Error adding translation:', error);
    }
  };

  // Edit Translation
  const handleEditTranslation = async () => {
    try {
      await axios.post(`${baseURL}editTranslation`, editTranslation);
      const response = await axios.post(`${baseURL}getTranslation`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setTranslations(response.data.translations);
      onEditClose();
      // Reset editTranslation state after closing
      setEditTranslation({
        translationType: '',
        translationEn: '',
        translationEs: '',
        translationFr: '',
        translationIt: '',
        translationPg: '',
        translationCh: '',
        translationRu: '',
        id: '67250ded0f689ab7f255f5f8',
        translationId: '',
      });
    } catch (error) {
      console.error('Error editing translation:', error);
    }
  };

  // Open Edit Modal
  const openEditModal = (translation) => {
    setEditTranslation({
      translationType: translation.translationType,
      translationEn: translation.translationEn,
      translationEs: translation.translationEs,
      translationFr: translation.translationFr,
      translationIt: translation.translationIt,
      translationPg: translation.translationPg,
      translationCh: translation.translationCh,
      translationRu: translation.translationRu,
      id: '67250ded0f689ab7f255f5f8',
      translationId: translation._id,
    });
    onEditOpen();
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Flex align="center" gap={2}>
          <Text color={textColor} fontSize="xl" fontWeight="600">
            App Translation
          </Text>
          <Text color="gray.500" fontSize="md">
            ({translations.length} entries)
          </Text>
        </Flex>
        <Button colorScheme="blue" onClick={onAddOpen}>
          Add Translation
        </Button>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>English</Th>
              <Th>Spanish</Th>
              <Th>French</Th>
              <Th>Italian</Th>
              <Th>Portuguese</Th>
              <Th>Chinese</Th>
              <Th>Russian</Th>

              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {translations.map((translation) => (
              <Tr key={translation._id}>
                <Td>{translation.translationType}</Td>
                <Td>{translation.translationEn}</Td>
                <Td>{translation.translationEs}</Td>
                <Td>{translation.translationFr}</Td>
                <Td>{translation.translationIt}</Td>
                <Td>{translation.translationPg}</Td>
                <Td>{translation.translationCh}</Td>
                <Td>{translation.translationRu}</Td>

                <Td>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => openEditModal(translation)}
                  >
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Add Translation Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Translation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Type</FormLabel>
              <Input
                name="translationType"
                value={newTranslation.translationType}
                onChange={handleInputChange}
                color={textColor}
                placeholder="Enter translation type"
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>English</FormLabel>
              <Input
                name="translationEn"
                value={newTranslation.translationEn}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Spanish</FormLabel>
              <Input
                name="translationEs"
                value={newTranslation.translationEs}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>French</FormLabel>
              <Input
                name="translationFr"
                value={newTranslation.translationFr}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Italian</FormLabel>
              <Input
                name="translationIt"
                value={newTranslation.translationIt}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>{' '}
            <FormControl mb="4">
              <FormLabel>Portuguese</FormLabel>
              <Input
                name="translationPg"
                value={newTranslation.translationPg}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>{' '}
            <FormControl mb="4">
              <FormLabel>Chinese</FormLabel>
              <Input
                name="translationCh"
                value={newTranslation.translationCh}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>{' '}
            <FormControl mb="4">
              <FormLabel>Russian</FormLabel>
              <Input
                name="translationRu"
                value={newTranslation.translationRu}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddTranslation}>
              Add
            </Button>
            <Button variant="ghost" onClick={onAddClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Edit Translation Modal */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Translation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {editTranslation && (
              <>
                <FormControl mb="4">
                  <FormLabel>Type</FormLabel>
                  <Input
                    name="translationType"
                    value={editTranslation.translationType}
                    onChange={handleInputChange}
                    color={textColor}
                    placeholder="Enter translation type"
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>English</FormLabel>
                  <Input
                    name="translationEn"
                    value={editTranslation.translationEn}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Spanish</FormLabel>
                  <Input
                    name="translationEs"
                    value={editTranslation.translationEs}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>French</FormLabel>
                  <Input
                    name="translationFr"
                    value={editTranslation.translationFr}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
                <FormControl mb="4">
                  <FormLabel>Italian</FormLabel>
                  <Input
                    name="translationIt"
                    value={editTranslation.translationIt}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>{' '}
                <FormControl mb="4">
                  <FormLabel>Portuguese</FormLabel>
                  <Input
                    name="translationPg"
                    value={editTranslation.translationPg}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>{' '}
                <FormControl mb="4">
                  <FormLabel>Chinese</FormLabel>
                  <Input
                    name="translationCh"
                    value={editTranslation.translationCh}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>{' '}
                <FormControl mb="4">
                  <FormLabel>Russian</FormLabel>
                  <Input
                    name="translationRu"
                    value={editTranslation.translationRu}
                    onChange={handleInputChange}
                    color={textColor}
                  />
                </FormControl>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleEditTranslation}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
