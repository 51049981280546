// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import BarChart from 'components/charts/BarChart';
import React, { useMemo } from 'react';
import { MdBarChart } from 'react-icons/md';
import { green } from 'theme/colors';

export default function WeeklyRevenue({ data = [] }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue(green, 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' }
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' }
  );

  const chartData = useMemo(() => {
    if (!data?.length) return {
      labels: [],
      data: []
    };

    return {
      labels: data.map(item => {
        const date = new Date(item.date);
        return date.toLocaleDateString('en-US', { weekday: 'short' });
      }),
      data: data.map(item => (item.revenue).toFixed(2))
    };
  }, [data]);

  const barChartDataWeeklyRevenue = [
    {
      name: 'Daily Revenue',
      data: chartData.data,
    },
  ];

  const barChartOptionsWeeklyRevenue = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
        },
      },
      theme: 'dark',
    },
    xaxis: {
      categories: chartData.labels,
      show: true,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: '#CBD5E0',
          fontSize: '14px',
        },
        formatter: function (value) {
          return '$' + value;
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: '#4318FF',
              opacity: 1,
            },
            {
              offset: 100,
              color: 'rgba(67, 24, 255, 1)',
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '40px',
      },
    },
  };

  return (
    <Card>
      <Flex justify='space-between' align='center' mb='24px' ps='0px' pe='20px'>
        <Text color={textColor} fontSize='xl' fontWeight='600' mt='4px'>
          Weekly Revenue
        </Text>
        <Button
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          borderRadius='10px'
        >
          <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
        </Button>
      </Flex>
      <Box minH='260px'>
        <BarChart
          chartData={barChartDataWeeklyRevenue}
          chartOptions={barChartOptionsWeeklyRevenue}
        />
      </Box>
    </Card>
  );
}
