import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  VStack,
  Input,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';
import { baseURLAUTH } from 'constants/constant';

export default function TicketTable() {
  const [tickets, setTickets] = React.useState([]);
  const [subadmins, setSubadmins] = React.useState([]);
  const [selectedTicket, setSelectedTicket] = React.useState(null);
  const [selectedSubadmin, setSelectedSubadmin] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [searchSubadmin, setSearchSubadmin] = React.useState('');
  const [filteredSubadmins, setFilteredSubadmins] = React.useState([]);

  const handleSelectSubadmin = (id) => {
    setSelectedSubadmin(id);
  };

  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();

  const {
    isOpen: isMessageOpen,
    onOpen: onMessageOpen,
    onClose: onMessageClose,
  } = useDisclosure();

  const {
    isOpen: isStatusOpen,
    onOpen: onStatusOpen,
    onClose: onStatusClose,
  } = useDisclosure();

  const getUser = async (email) => {
    const get = await axios.post(`${baseURLAUTH}getUser`, {
      email,
    });
    if (get.data?.user) {
      setSelectedUser(get?.data?.user);
    }
  };
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const fetchTickets = async () => {
    try {
      const response = await axios.post(`${baseURL}getAllTickets`);
      setTickets(response.data.tickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };
  React.useEffect(() => {
    fetchTickets();
  }, []);

  // Fetch Subadmins
  React.useEffect(() => {
    const fetchSubadmins = async () => {
      try {
        const response = await axios.post(`${baseURL}getSupportAdmins`);
        setSubadmins(response.data.supportAdmins);
        setFilteredSubadmins(response.data.supportAdmins);
      } catch (error) {
        console.error('Error fetching subadmins:', error);
      }
    };
    fetchSubadmins();
  }, []);

  // Open Assignment Modal
  const handleAssignOpen = (ticket) => {
    setSelectedTicket(ticket);
    onAssignOpen();
  };

  const handleStatusOpen = (ticket) => {
    setSelectedTicket(ticket);
    onStatusOpen();
  };

  const updateTicketStatus = async (status, ticket) => {
    const response = await axios.post(`${baseURL}changeTicketStatus`, {
      newStatus: status,
      ticketId: ticket._id,
      adminId: '67250ded0f689ab7f255f5f8',
    });
    if (response.data) {
      onStatusClose();
      fetchTickets();
    }
  };

  const handleAssign = async (subadminId) => {
    if (selectedTicket) {
      try {
        await axios.post(`${baseURL}assignTicketToSupportAdmin`, {
          ticketId: selectedTicket._id,
          id: subadminId,
        });
        onAssignClose();
        fetchTickets();
      } catch (error) {
        console.error('Error assigning ticket:', error);
      }
    }
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          Tickets
        </Text>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Email</Th>
              <Th>Subject</Th>
              <Th>Status</Th>
              <Th>Messages</Th>
              <Th>Assigned</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tickets &&
              tickets.map((ticket) => (
                <Tr key={ticket._id} color={textColor}>
                  <Td>{ticket.ticketID}</Td>
                  <Td>{ticket.email}</Td>
                  <Td>{ticket.subject}</Td>
                  <Td>{ticket.status}</Td>
                  <Td
                    onClick={async () => {
                      setSelectedTicket(ticket);
                      await getUser(ticket.email);
                      onMessageOpen();
                    }}
                    cursor={'pointer'}
                  >
                    {ticket.messages.length}
                  </Td>
                  <Td>
                    {ticket.assigned === 'assigned'
                      ? ticket.assignedTo
                      : 'Unassigned'}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => handleAssignOpen(ticket)}
                    >
                      {ticket?.assignedTo ? 'Reassign' : 'Assign'}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme={ticket.status === 'closed' ? 'red' : 'blue'}
                      onClick={() => {
                        if (ticket.status === 'closed') return;
                        handleStatusOpen(ticket);
                      }}
                    >
                      {ticket?.status === 'closed' ? 'Closed' : 'Close'}
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      {/* Assign Modal */}
      <Modal isOpen={isAssignOpen} onClose={onAssignClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="4">Select a subadmin to assign the ticket:</Text>
            <Input
              type="text"
              placeholder="Search subadmins"
              mb="4"
              onChange={(e) => {
                setSearchSubadmin(e.target.value);
                if (e.target.value === '') {
                  setFilteredSubadmins(subadmins);
                } else {
                  setFilteredSubadmins(
                    subadmins.filter((subadmin) =>
                      subadmin?.firstName
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()),
                    ),
                  );
                }
              }}
              color={textColor}
            />
            <VStack spacing={4} align="stretch">
              {filteredSubadmins &&
                filteredSubadmins
                  .filter((subadmin) => subadmin?.active === true)
                  .map((subadmin) => (
                    <Box
                      key={subadmin._id}
                      borderWidth={1}
                      borderRadius="md"
                      p={3}
                      onClick={() => handleSelectSubadmin(subadmin._id)}
                      cursor="pointer"
                      _hover={{ bg: 'green.700' }}
                      bg={
                        selectedSubadmin === subadmin._id
                          ? 'green.500'
                          : 'gray.500'
                      }
                    >
                      <Text
                        fontWeight="bold"
                        color="white.500"
                      >{`${subadmin.firstName} ${subadmin.lastName}`}</Text>
                      <Text color="white.500">{subadmin.emailAddress}</Text>
                    </Box>
                  ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onAssignClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={async () => {
                await handleAssign(selectedSubadmin);
              }}
              isDisabled={!selectedSubadmin}
              ml={3}
            >
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isMessageOpen} onClose={onMessageClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ticket Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedTicket && selectedTicket?.messages?.length > 0 ? (
              selectedTicket?.messages.map((message, index) => (
                <Box
                  key={index}
                  p={3}
                  mb={2}
                  borderWidth="1px"
                  borderRadius="md"
                  bg="gray.50"
                  _dark={{ bg: 'gray.800' }}
                >
                  <Text fontWeight="bold">
                    From:{' '}
                    {message.sender === selectedUser?._id ? 'User' : 'SubAdmin'}
                  </Text>
                  <Text>{message.content}</Text>
                </Box>
              ))
            ) : (
              <Text>No messages found for this ticket.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onMessageClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isStatusOpen} onClose={onStatusClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Close Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to close this ticket?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => updateTicketStatus('closed', selectedTicket)}
            >
              Close
            </Button>
            <Button variant="ghost" onClick={onStatusClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
