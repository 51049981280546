import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function TopUserTable() {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [editEmailTemplate, setEditEmailTemplate] = useState({
    emailType: '',
    subject: {},
    body: {},
    id: '67250ded0f689ab7f255f5f8',
    emailId: '',
  });

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.post(`${baseURL}getEmails`, {
          id: '67250ded0f689ab7f255f5f8',
        });
        setEmailTemplates(response.data.emails);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setEditEmailTemplate((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [lang]: value,
      },
    }));
  };

  const handleEditEmail = async () => {
    try {
      await axios.post(`${baseURL}editEmail`, editEmailTemplate);
      const response = await axios.post(`${baseURL}getEmails`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setEmailTemplates(response.data.emails);
      onEditClose();
      setEditEmailTemplate({
        emailType: '',
        subject: {},
        body: {},
        id: '67250ded0f689ab7f255f5f8',
        emailId: '',
      });
    } catch (error) {
      console.error('Error editing email:', error);
    }
  };

  const openEditModal = (template) => {
    setEditEmailTemplate({
      emailType: template.emailType,
      subject: template.subject,
      body: template.body,
      id: '67250ded0f689ab7f255f5f8',
      emailId: template._id,
    });
    onEditOpen();
  };

  return (
    <Flex direction="column" w="100%" overflowX="auto">
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        <Text color={textColor} fontSize="xl" fontWeight="600">
          Email Templates
        </Text>
      </Flex>

      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            <Tr>
              <Th>Email Type</Th>
              <Th>Subject (EN)</Th>
              <Th>Subject (ES)</Th>
              <Th>Subject (FR)</Th>
              <Th>Subject (IT)</Th>
              <Th>Subject (PG)</Th>
              <Th>Subject (CH)</Th>
              <Th>Subject (RU)</Th>
              <Th>Body (EN)</Th>
              <Th>Body (ES)</Th>
              <Th>Body (FR)</Th>
              <Th>Body (IT)</Th>
              <Th>Body (PG)</Th>
              <Th>Body (CH)</Th>
              <Th>Body (RU)</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {emailTemplates && emailTemplates.map((template) => (
              <Tr key={template._id}>
                <Td>{template.emailType}</Td>
                <Td>{template.subject?.en || '-'}</Td>
                <Td>{template.subject?.es || '-'}</Td>
                <Td>{template.subject?.fr || '-'}</Td>
                <Td>{template.subject?.it || '-'}</Td>
                <Td>{template.subject?.pg || '-'}</Td>
                <Td>{template.subject?.ch || '-'}</Td>
                <Td>{template.subject?.ru || '-'}</Td>
                <Td>{template.body?.en || '-'}</Td>
                <Td>{template.body?.es || '-'}</Td>
                <Td>{template.body?.fr || '-'}</Td>
                <Td>{template.body?.it || '-'}</Td>
                <Td>{template.body?.pg || '-'}</Td>
                <Td>{template.body?.ch || '-'}</Td>
                <Td>{template.body?.ru || '-'}</Td>
                <Td>
                  <Button onClick={() => openEditModal(template)}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Email Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Email Type</FormLabel>
              <Input
                name="emailType"
                value={editEmailTemplate.emailType}
                onChange={(e) =>
                  setEditEmailTemplate((prev) => ({
                    ...prev,
                    emailType: e.target.value,
                  }))
                }
                color={textColor}
              />
            </FormControl>
            {Object.entries(editEmailTemplate.subject).map(([lang, text]) => (
              <FormControl key={lang} mb="4">
                <FormLabel>Subject ({lang.toUpperCase()})</FormLabel>
                <Input
                  name={`subject.${lang}`}
                  value={text}
                  onChange={handleInputChange}
                  color={textColor}
                />
              </FormControl>
            ))}
            {Object.entries(editEmailTemplate.body).map(([lang, text]) => (
              <FormControl key={lang} mb="4">
                <FormLabel>Body ({lang.toUpperCase()})</FormLabel>
                <Input
                  name={`body.${lang}`}
                  value={text}
                  onChange={handleInputChange}
                  color={textColor}
                />
              </FormControl>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleEditEmail}>
              Save
            </Button>
            <Button variant="ghost" onClick={onEditClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
